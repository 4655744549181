import React, { Component } from "react";
import { connect } from "react-redux";

import { updateEstampador } from "../actions/estampador";
import { updateTemplate } from "../actions/template";
import { updatePedido } from "../actions/pedido";
import { updateLoader } from "../actions/loader";
import { updateErrorModal } from "../actions/errorModal";

import { api } from "../services/api";

import Base from "../components/Base";
import CardDetail from "../components/CardDetail";
import Solicitacao from "../components/Solicitacao";
import SolicitacaoRenavam from "../components/SolicitacaoRenavam";
import SolicitacaoChassi from "../components/SolicitacaoChassi";
import SolicitacaoDetran from "../components/SolicitacaoDetran";
import SolicitacaoDetranComPlaca from "../components/SolicitacaoDetranComPlaca";
import SolicitacaoDetranRenavamPlaca from "../components/SolicitacaoDetranRenavamPlaca";
import SolicitacaoPlacaComChassi from "../components/SolicitacaoPlacaComChassi";
import SolicitacaoDenatranPlaca from "../components/SolicitacaoDenatranPlaca";
import SolicitacaoPlaca from "../components/SolicitacaoPlaca";
import ConsultBar from "../components/ConsultBar";

import { redirectToStep } from "../helpers";

import '../assets/styles/intern.scss';

const ESTAMPADOR_URL = `estampadores/`;
const PEDIDO_BY_ESTAMPADOR_URL = `pedidos/placa​/{placa}/?idEstampador={idEstampador}`;

class Estampador extends Component {
  // Lifecycle methods
  async componentDidMount() {
    await this.fetchEstampador(this.props.match.params.estampador);
    this.prepareTemplate();
  }

  // Fetch data methods
  fetchEstampador = async url => {
    this.props.updateLoader(true);

    try {
      const estampador = await api.get(`${ESTAMPADOR_URL}${url}`).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.props.updateEstampador(estampador.data);
    } catch (err) {
      this.props.updateErrorModal(true);
    }

    this.props.updateLoader(false);
  }

  fetchPedidoByEstampador = async placa => {
    let url = PEDIDO_BY_ESTAMPADOR_URL;
    url = url.replace(`{placa}`, placa).replace(`{idEstampador}`, this.props.estampador.id);

    this.props.updateLoader(true);

    try {
      const pedido = await api.get(url).catch(err => { throw err; });

      this.props.updateLoader(false);

      this.handleValidPlate(pedido.data);
    } catch (err) {
      this.handleInvalidPlate(err);
    }

    this.props.updateLoader(false);
  }

  // Helpers methods
  handleValidPlate = pedido => {
    this.props.updatePedido(pedido);

    const path = `${this.props.estampador.uf.toLowerCase()}/${this.props.estampador.url}`;

    redirectToStep(pedido.status, this.props.history, path);
  }

  handleInvalidPlate = err => {
    if (err.response.status === 404 || err.response.status === 409) {
      this.props.history.push("/pedido-nao-encontrado");
    }
    if (err.response.status === 500) {
      this.props.updateErrorModal(true);
    }
  }

  prepareTemplate = _ => {
    const template = {
      corPrimaria: this.props.estampador.corPrimaria,
      corSecundaria: this.props.estampador.corSecundaria,
      corTexto: this.props.estampador.corTexto
    }

    this.props.updateTemplate(template);
  }

  renderSolicitacaoComponent() {
    const { estampador } = this.props;
    const uf = estampador.uf.toLowerCase();

    switch(uf){
        case 'ba':
          return <SolicitacaoPlaca />
        case 'mt':
            return <SolicitacaoPlaca />
        case 'es': 
          return <SolicitacaoDetranComPlaca />;
        case 'go':
          return <SolicitacaoDetran />;
        case 'rs':
            return <SolicitacaoPlaca />;
        case 'ma':
          return <SolicitacaoDenatranPlaca />;
        case 'pr':
          return <SolicitacaoDetranRenavamPlaca />;
        case 'se': 
        case 'rn': 
          return <SolicitacaoRenavam />;
        default: 
          return <Solicitacao />
    }
  }

  render() {
    return (
      <Base hideTitle={ true }>
        <div className="internContainer">
          <CardDetail />
          {this.renderSolicitacaoComponent()}
        </div>
        <ConsultBar onFetchPedido={ e => this.fetchPedidoByEstampador(e) } />
      </Base>
    );
  }
}

const mapStateToProps = store => ({
  estampador: store.estampadorState.estampador
});

const mapDispatchToProps = {
  updateEstampador: updateEstampador,
  updateTemplate: updateTemplate,
  updatePedido: updatePedido,
  updateLoader: updateLoader,
  updateErrorModal: updateErrorModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Estampador);
